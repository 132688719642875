<script lang="ts" setup>
import Logo from '@components/logo/index.vue'
import MainMenu from '@components/main-menu/index.vue'
import LangSwitch from '@components/lang-switch/index.vue'
import Button from '@components/ui/button/index.vue'

import IconMenu from '@components/ui/icons/menu.vue'
import IconClose from '@components/ui/icons/close.vue'

import { useMobileMenu } from './use-mobile-menu'

const { t } = useI18n()
const localePath = useLocalePath()
const { isOpened, onOpenMenu, onCloseMenu } = useMobileMenu()
</script>

<template>
  <button @click="onOpenMenu">
    <IconMenu />
  </button>

  <div class="mobile-modal" :class="{ 'is-open': isOpened }">
    <button class="mobile-modal__close" @click="onCloseMenu">
      <IconClose />
    </button>
    <div class="mobile-modal__overlay">
      <div class="mobile-menu">
        <div>
          <Logo />

          <MainMenu />
        </div>

        <div class="flex flex-col gap-[42px]">
          <LangSwitch />

          <div class="flex flex-col gap-[16px]">
            <NuxtLink :to="localePath('/signin')">
              <Button type="subtle" size="small">{{
                t('main_menu.signin')
              }}</Button>
            </NuxtLink>

            <NuxtLink :to="localePath('/signup')">
              <Button type="secondary" size="small">{{
                t('main_menu.signup')
              }}</Button>
            </NuxtLink>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<style lang="scss">
// For body
.mobile-menu-opened {
  overflow: hidden;
}

.mobile-modal {
  position: absolute;
  z-index: 999;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;

  display: none;

  &.is-open {
    display: block;
  }

  &__close {
    position: absolute;
    top: 20px;
    right: 20px;
  }

  &__overlay {
    height: 100%;

    background: rgb(45, 45, 45, 0.7);
  }
}

.mobile-menu {
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  width: 300px;
  height: 100%;
  padding: 24px;

  background: var(--c-bg);

  & .logo {
    margin-bottom: 48px;
  }
}
</style>
