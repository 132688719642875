import { onClickOutside } from '@vueuse/core'

import type { TDropdownMenuProps } from './dropdown-menu'

export const useDropdownMenu = (props: TDropdownMenuProps) => {
  const dropdownMenuRef = ref(undefined)
  const isOpened = ref(false)

  onClickOutside(dropdownMenuRef, () => {
    isOpened.value = false
  })

  function onOpen() {
    isOpened.value = !isOpened.value
  }

  return { dropdownMenuRef, isOpened, onOpen }
}
