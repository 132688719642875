<script lang="ts" setup>
import { dropdownMenuProps } from './dropdown-menu'
import { useDropdownMenu } from './use-dropdown-menu'

import UpIcon from '@components/ui/icons/up.vue'
import DownIcon from '@components/ui/icons/down.vue'

const props = defineProps(dropdownMenuProps)
const { dropdownMenuRef, isOpened, onOpen } = useDropdownMenu(props)
const { head } = toRefs(props)
</script>

<template>
  <div ref="dropdownMenuRef" class="dropdown-menu">
    <div class="dropdown-menu__header" @click.stop="onOpen">
      <div class="dropdown-menu__header-head">{{ head }}</div>

      <div class="dropdown-menu__header-icon">
        <UpIcon v-if="isOpened" />
        <DownIcon v-else />
      </div>
    </div>

    <div class="dropdown-menu__content" :class="{ 'is-open': isOpened }">
      <slot name="menu" />
    </div>
  </div>
</template>

<style lang="scss">
.dropdown-menu {
  position: relative;

  border-width: 1px 0 1px 0;
  border-style: solid;
  border-color: var(--c-grayscale-body);

  &__header {
    padding: 15px 20px;

    cursor: pointer;

    &-head {
      color: var(--c-grayscale-placeholder);

      @include t-mobile-link-x-small;
    }

    &-icon {
      position: absolute;
      top: 16px;
      right: 20px;
    }
  }

  &__content {
    display: none;

    padding: 0 20px 20px;

    &.is-open {
      display: block;
    }
  }
}

.dropdown-menu + .dropdown-menu {
  border-top: 0px;
}
</style>
