<script lang="ts" setup>
import { langSwitchProps } from './lang-switch'
import { useLangSwitch } from './use-lang-switch'

import UpIcon from '@components/ui/icons/up.vue'
import DownIcon from '@components/ui/icons/down.vue'

const props = defineProps(langSwitchProps)

const { locale, langSwitchRef, isOpened, currentLocale, localesList, onOpen } =
  useLangSwitch(props)
const { type } = toRefs(props)
</script>

<template>
  <div v-if="type === 'menu'" class="switch-lang-menu">
    <SwitchLocalePathLink
      class="switch-lang-menu__link"
      v-for="lang in localesList"
      :class="{ 'active-lang': locale === lang.code }"
      :key="lang.code"
      :locale="lang.code"
      :external="true"
      >{{ lang.code.toUpperCase() }}</SwitchLocalePathLink
    >
  </div>

  <div
    ref="langSwitchRef"
    v-else
    class="switch-lang-dropdown"
    :class="{ 'is-open': isOpened }"
    @click.stop="onOpen"
  >
    <div class="switch-lang-dropdown__current">
      {{ currentLocale?.name || '' }}

      <div class="switch-lang-dropdown__icon">
        <UpIcon v-if="isOpened" />
        <DownIcon v-else />
      </div>
    </div>

    <div class="switch-lang-dropdown__list" :class="{ 'is-open': isOpened }">
      <SwitchLocalePathLink
        class="switch-lang-dropdown__list-item"
        v-for="lang in localesList"
        :key="lang.code"
        :locale="lang.code"
        :external="true"
        >{{ lang.name }}</SwitchLocalePathLink
      >
    </div>
  </div>
</template>

<style lang="scss">
.switch-lang-menu {
  display: flex;

  gap: 40px;

  @media (max-width: 1439px) {
    gap: 24px;
  }

  &__link {
    position: relative;

    cursor: pointer;

    color: var(--c-primary-default);

    @include t-desktop-link-small;

    &:hover {
      color: var(--c-primary-light);
    }

    &:hover.active-lang::after {
      background: var(--c-primary-light);
    }

    &.active-lang::after {
      position: absolute;
      bottom: 0;
      left: 0;

      width: 100%;
      height: 2px;

      content: '';

      background: var(--c-primary-default);
    }
  }
}

.switch-lang-dropdown {
  position: relative;

  width: 170px;

  cursor: pointer;

  border-radius: 15px;
  --border-radius: 15px;
  background: var(--c-grayscale-body);

  @include t-mobile-text-small;
  --item-padding: 5px 24px;

  &__current {
    padding: var(--item-padding);

    color: var(--c-grayscale-placeholder);
  }

  &__icon {
    position: absolute;
    top: 6px;
    right: 20px;
  }

  &.is-open {
    border-radius: var(--border-radius) var(--border-radius) 0 0;
  }

  &__list {
    position: absolute;

    display: none;

    width: 100%;

    border-radius: 0 0 var(--border-radius) var(--border-radius);
    background: var(--c-grayscale-body);

    &-item {
      width: 100%;
      padding: var(--item-padding);

      color: var(--c-grayscale-placeholder);
      border-radius: 0 0 var(--border-radius) var(--border-radius);

      &:hover {
        background: #060710;
      }
    }

    &.is-open {
      display: flex;
    }
  }
}
</style>
