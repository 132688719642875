import { onClickOutside } from '@vueuse/core'

import type { LocaleObject } from '@nuxtjs/i18n'
import type { TLangSwitchProps } from './lang-switch'

export const useLangSwitch = (props: TLangSwitchProps) => {
  const { locale, locales } = useI18n()

  const langSwitchRef = ref(undefined)
  const isOpened = ref(false)

  const currentLocale = computed<LocaleObject | undefined>(() => {
    return locales.value.find((localeItem) => localeItem.code == locale.value)
  })

  const localesList = computed<LocaleObject[]>(() => {
    if (props.type === 'menu') return locales.value

    return locales.value.filter((localeItem) => localeItem.code != locale.value)
  })

  onClickOutside(langSwitchRef, () => (isOpened.value = false))

  function onOpen() {
    isOpened.value = !isOpened.value
  }

  return {
    locale,
    langSwitchRef,
    isOpened,
    currentLocale,
    localesList,
    onOpen,
  }
}
