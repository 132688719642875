<template>
  <svg
    width="32"
    height="20"
    viewBox="0 0 32 22"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <rect y="0" width="32" height="5.45192" rx="2.72596" fill="#24CDAF" />
    <rect y="8" width="32" height="5.45192" rx="2.72596" fill="#24CDAF" />
    <rect y="16.2744" width="32" height="5.45192" rx="2.72596" fill="#24CDAF" />
  </svg>
</template>
