<script lang="ts" setup></script>

<template>
  <div class="logo">
    <NuxtLink to="/">
      <img src="/logo.svg" />
    </NuxtLink>
  </div>
</template>

<style lang="scss"></style>
