export const useMobileMenu = () => {
  const route = useRoute()
  const isOpened = ref(false)

  function onOpenMenu() {
    isOpened.value = true

    document.body.classList.add('mobile-menu-opened')
  }

  function onCloseMenu() {
    isOpened.value = false

    document.body.classList.remove('mobile-menu-opened')
  }

  watch(() => route.path, onCloseMenu, { deep: true })

  return {
    isOpened,
    onOpenMenu,
    onCloseMenu,
  }
}
