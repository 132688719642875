import type { ExtractPropTypes } from 'vue'

export const dropdownMenuProps = {
  head: {
    type: String,
    default: '',
  },
} as const

export type TDropdownMenuProps = ExtractPropTypes<typeof dropdownMenuProps>
