<script lang="ts" setup>
import LangSwitch from '@components/lang-switch/index.vue'
import DropdownMenu from '@components/ui/dropdown-menu/index.vue'
import Logo from '@components/logo/index.vue'

const { t } = useI18n()
</script>

<template>
  <footer class="footer">
    <div class="main-container">
      <div class="footer__content">
        <div class="footer__common md:w-3/12 sm:w-full text-nowrap">
          <Logo />

          <ul class="footer-menu">
            <li class="footer-menu__item">
              <span>{{ t('footer.phone') }}: </span>
              <a class="footer-menu__link" href="tel:+79999999999"
                >+7 999 999 99 99</a
              >
            </li>
            <li class="footer-menu__item">
              <span>{{ t('footer.email') }}: </span>
              <a class="footer-menu__link" href="mailto:support@pfd.com"
                >support@pfd.com</a
              >
            </li>
          </ul>

          <LangSwitch class="footer__common-lang" type="dropdown" />
        </div>

        <div class="md:flex justify-between w-8/12 is-mobile">
          <ul class="footer-menu">
            <p class="footer-menu__title">{{ t('footer.general') }}</p>

            <li class="footer-menu__item">
              <NuxtLink class="footer-menu__link">{{
                t('footer.about_project')
              }}</NuxtLink>
            </li>
            <li class="footer-menu__item">
              <NuxtLink class="footer-menu__link">{{
                t('footer.plans')
              }}</NuxtLink>
            </li>
            <li class="footer-menu__item">
              <NuxtLink class="footer-menu__link">{{
                t('footer.contacts')
              }}</NuxtLink>
            </li>
            <li class="footer-menu__item">
              <NuxtLink class="footer-menu__link">{{
                t('footer.faq')
              }}</NuxtLink>
            </li>
          </ul>

          <ul class="footer-menu">
            <p class="footer-menu__title">{{ t('footer.account') }}</p>

            <li class="footer-menu__item">
              <NuxtLink class="footer-menu__link">{{
                t('footer.go_to_profile')
              }}</NuxtLink>
            </li>
          </ul>

          <ul class="footer-menu">
            <p class="footer-menu__title">{{ t('footer.for_developer') }}</p>

            <li class="footer-menu__item">
              <NuxtLink class="footer-menu__link">{{
                t('footer.documentation')
              }}</NuxtLink>
            </li>
          </ul>
        </div>

        <div class="md:hidden sm:flex flex-col">
          <DropdownMenu :head="t('footer.general')">
            <template #menu>
              <ul class="footer-menu">
                <li class="footer-menu__item">
                  <NuxtLink class="footer-menu__link">{{
                    t('footer.about_project')
                  }}</NuxtLink>
                </li>
                <li class="footer-menu__item">
                  <NuxtLink class="footer-menu__link">{{
                    t('footer.plans')
                  }}</NuxtLink>
                </li>
                <li class="footer-menu__item">
                  <NuxtLink class="footer-menu__link">{{
                    t('footer.contacts')
                  }}</NuxtLink>
                </li>
                <li class="footer-menu__item">
                  <NuxtLink class="footer-menu__link">{{
                    t('footer.faq')
                  }}</NuxtLink>
                </li>
              </ul>
            </template>
          </DropdownMenu>

          <DropdownMenu :head="t('footer.account')">
            <template #menu>
              <ul class="footer-menu">
                <li class="footer-menu__item">
                  <NuxtLink class="footer-menu__link">{{
                    t('footer.go_to_profile')
                  }}</NuxtLink>
                </li>
              </ul>
            </template>
          </DropdownMenu>

          <DropdownMenu :head="t('footer.for_developer')">
            <template #menu>
              <ul class="footer-menu">
                <li class="footer-menu__item">
                  <NuxtLink class="footer-menu__link">{{
                    t('footer.documentation')
                  }}</NuxtLink>
                </li>
              </ul>
            </template>
          </DropdownMenu>
        </div>
      </div>

      <div class="footer__copy">
        <div class="flex w-full justify-between is-mobile">
          <ul class="footer-menu">
            <li class="footer-menu__item">©2021 Proxy for Developers</li>
          </ul>

          <ul class="footer-menu">
            <li class="footer-menu__item">
              <NuxtLink class="footer-menu__link">{{
                t('footer.terms_service')
              }}</NuxtLink>
            </li>
            <li class="footer-menu__item">
              <NuxtLink class="footer-menu__link">{{
                t('footer.service_agreement')
              }}</NuxtLink>
            </li>
          </ul>

          <ul class="footer-menu">
            <li class="footer-menu__item">
              <NuxtLink class="footer-menu__link">{{
                t('footer.privacy_policy')
              }}</NuxtLink>
            </li>
            <li class="footer-menu__item">
              <NuxtLink class="footer-menu__link">{{
                t('footer.cookie_policy')
              }}</NuxtLink>
            </li>
          </ul>
        </div>

        <div class="flex md:hidden">
          <ul class="footer-menu">
            <li class="footer-menu__item">©2021 Proxy for Developers</li>
            <li class="footer-menu__item">
              <NuxtLink class="footer-menu__link">{{
                t('footer.terms_service')
              }}</NuxtLink>
            </li>
            <li class="footer-menu__item">
              <NuxtLink class="footer-menu__link">{{
                t('footer.service_agreement')
              }}</NuxtLink>
            </li>
            <li class="footer-menu__item">
              <NuxtLink class="footer-menu__link">{{
                t('footer.privacy_policy')
              }}</NuxtLink>
            </li>
            <li class="footer-menu__item">
              <NuxtLink class="footer-menu__link">{{
                t('footer.cookie_policy')
              }}</NuxtLink>
            </li>
          </ul>
        </div>
      </div>
    </div>
  </footer>
</template>

<style lang="scss">
.footer {
  margin: 96px 0;

  &__common {
    & .logo {
      margin-bottom: 16px;
    }

    &-lang {
      margin-top: 48px;

      @media (max-width: 1023px) {
        margin-top: 24px;
      }
    }
  }

  &__content {
    display: flex;

    @media (max-width: 1023px) {
      flex-direction: column;

      gap: 20px;
    }
  }

  &__copy {
    display: flex;
    justify-content: space-between;

    margin-top: 40px;
    padding-top: 40px;

    border-top: 1px solid var(--c-grayscale-body);

    @media (max-width: 1023px) {
      flex-direction: column;

      margin-top: 20px;
      padding-top: 0px;

      border-top: none;
    }
  }
}

.footer-menu {
  &__title {
    margin-bottom: 16px;

    color: var(--c-grayscale-line);

    @include t-desktop-link-small;
  }

  &__item {
    color: var(--c-grayscale-placeholder);

    @include t-desktop-text-x-small;

    &:not(:last-child) {
      margin-bottom: 8px;
    }
  }

  &__link {
    cursor: pointer;

    color: var(--c-grayscale-placeholder);
  }
}
</style>
