import type { PropType, ExtractPropTypes } from 'vue'

export const langSwitchProps = {
  type: {
    type: String as PropType<'menu' | 'dropdown'>,
    default: 'menu',
  },
} as const

export type TLangSwitchProps = ExtractPropTypes<typeof langSwitchProps>
