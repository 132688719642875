<script lang="ts" setup>
const { t } = useI18n()
const localePath = useLocalePath()
</script>

<template>
  <div class="main-menu">
    <ul class="main-menu__items">
      <li class="main-menu__item">
        <NuxtLink class="main-menu__link" :to="localePath('/')">{{
          t('main_menu.documentation')
        }}</NuxtLink>
      </li>
      <li class="main-menu__item">
        <NuxtLink class="main-menu__link" :to="localePath('/')">{{
          t('main_menu.examination')
        }}</NuxtLink>
      </li>
      <li class="main-menu__item">
        <NuxtLink class="main-menu__link" :to="localePath('/')">{{
          t('main_menu.faq')
        }}</NuxtLink>
      </li>
    </ul>
  </div>
</template>

<style lang="scss">
.main-menu {
  &__items {
    display: flex;

    gap: 40px;

    @media (max-width: 1439px) {
      gap: 24px;
    }

    @media (max-width: 1023px) {
      flex-direction: column;

      gap: 32px;
    }
  }

  &__link {
    cursor: pointer;

    color: var(--c-primary-default);

    @include t-desktop-link-small;

    &:hover {
      color: var(--c-primary-light);
    }
  }
}
</style>
