<script lang="ts" setup>
import Logo from '@components/logo/index.vue'
import MainMenu from '@components/main-menu/index.vue'
import MobileMenu from '@components/mobile-menu/index.vue'
import LangSwitch from '@components/lang-switch/index.vue'
import Button from '@components/ui/button/index.vue'

const { t } = useI18n()
const localePath = useLocalePath()
</script>

<template>
  <header class="header">
    <div class="main-container">
      <Logo />

      <MainMenu />

      <div class="flex items-center lg:gap-[40px] md:gap-[24px]">
        <LangSwitch />

        <NuxtLink :to="localePath('/signin')">
          <Button type="subtle" size="small">{{
            t('main_menu.signin')
          }}</Button>
        </NuxtLink>

        <NuxtLink :to="localePath('/signup')">
          <Button type="secondary" size="small">{{
            t('main_menu.signup')
          }}</Button>
        </NuxtLink>
      </div>
    </div>

    <div class="main-container main-container-mobile">
      <MobileMenu />
    </div>
  </header>
</template>

<style lang="scss">
.header {
  display: flex;

  margin: 26px 0 96px 0;

  & .main-container {
    display: flex;
    align-items: center;
    justify-content: space-between;

    width: 100%;

    &-mobile {
      display: none;
    }

    @media (max-width: 1023px) {
      display: none;

      &-mobile {
        display: flex;
      }
    }
  }
}
</style>
